:root {
  --grey: rgb(94, 110, 130);
}
body {
  background-color: #edf2f9 !important;
}

h2 {
  font-weight: 800;
  font-size: 1.75rem !important;
}
.navbar {
  z-index: 999;
}
.btn {
  border-radius: 8px !important;
  padding: 0.375rem 0.75rem 0.5rem !important;
  font-size: 0.9rem !important;
}
.btn-success {
  background-color: #2fcc8b !important;
  border-color: #2fcc8b !important;
}
.btn-danger {
  background-color: #fe6549 !important;
  border-color: #fe6549 !important;
}
.btn-secondary {
  color: #fff;
  background-color: #979da1 !important;
  border-color: #979da1 !important;
}
li.add-new {
  margin-top: 15px;
  text-align: center;
}
.progress-bar {
  opacity: 0.8;
}
.grid-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
}
.grid-list.m {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}
.grid-list.s {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 10px;
}
.dropdown-menu {
  border: 0px !important;
  box-shadow: 0px 1px 5px #00000008;
}
.dropdown-menu a {
  color: #616161 !important;
  font-size: 14px;
}
.dropdown-menu a:hover {
  color: #007bff !important;
  text-decoration: none !important;
}

.dropdown-toggle:after {
  content: none !important;
}

.search-bar {
  width: 100%;
  padding: 15px 10px;
  border: 0px;
  border-radius: 8px;
  margin: 15px 0px 0px 0px;
  outline-width: 0 !important;
  box-shadow: 0px 1px 5px #00000008;
}

.PhoneInput input,
.input-bar {
  width: 100% !important;
  padding: 15px 10px !important;
  border: 0px !important;
  border-radius: 8px !important;
  margin: 5px 0px !important;
  outline-width: 0 !important;
  box-shadow: 0px 1px 5px #00000008;
  height: 50px !important;
}

textarea.input-bar {
  height: auto !important;
}

.suggestion-item {
  border-bottom: 1px solid #8080803b;
  cursor: pointer;
}

.suggestion-item--active {
  border-bottom: 1px solid #8080803b;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
}

.suggestion-item {
  border-bottom: 1px solid #8080803b;
  cursor: pointer;
  padding: 10px;
  border-radius: 8px;
}

/* div.input-bar {
    box-shadow: 0px 0px 0px #00000008;
} */
.PhoneInput {
  padding: 15px 0px !important;
  margin-left: 10px !important;
  width: calc(100% - 10px) !important;
}

.PhoneInput input {
  margin-left: 10px !important;
}

label {
  margin-bottom: 0px;
  margin-left: 3px;
  font-weight: 600;
  font-size: 14px;
}

.modal-header,
.modal-footer {
  border: none !important;
}

.modal-content {
  background-color: #edf2f9 !important;
}

.content-scroll {
  min-height: 60vh;
}

.content-scroll.sticky {
  padding-top: 145px;
}

.page-header {
  z-index: 999;
}

.page-header.sticky {
  position: fixed;
  top: 0;
  padding-bottom: 20px;
  background: linear-gradient(#edf2f9 93%, transparent);
}

.inner-container {
  min-height: 80vh;
}

.inner-container .page-header.sticky {
  width: calc(100vw - 35px);
}

.inner-container.active .page-header.sticky {
  width: calc(100vw - 290px);
}

.circle-picker {
  margin-bottom: 10px !important;
  margin-left: -10px !important;
  margin-top: -20px !important;
}

.team-item {
  width: 100%;
  padding: 20px 10px 10px 10px;
  border: 0px;
  border-radius: 8px;
  outline-width: 0 !important;
  background-color: #fff;
  box-shadow: 0px 1px 5px #00000008;
  color: #fff;
  position: relative;
  overflow: hidden;
}

.team-item h6 {
  font-size: 1.5rem;
  margin-bottom: 0.01rem;
  margin-top: 60px;
}

.team-bg-icon {
  position: absolute;
  top: 0;
  right: 0;
}

.team-item.small {
  width: 30%;
  min-width: 140px;
  padding: 10px;
  display: inline-block;
  margin-right: 10px;
}
.team-item.small h6 {
  max-width: 90px;
  overflow: hidden;
  overflow: hidden;
  font-size: 0.9rem;
  margin-bottom: 0.01rem;
  margin-top: 1px;
  cursor: pointer;
  transition: all 350ms ease;
}

.team-item.small .team-bg-icon {
  padding-right: 10px;
  padding-top: 10px;
  cursor: pointer;
}

.team-item.small:hover {
  box-shadow: 0px 8px 5px #00000008;
}

.team-choice-container {
  margin-top: 0.1rem;
}

.white-box,
.flat-box {
  border-radius: 8px;
  box-shadow: 0px 1px 5px #00000008;
  background-color: #fff;
}
/* .flat-box {
  border-radius: 8px;
  border: 2px solid rgb(94, 110, 130);
  color: rgb(94, 110, 130);
} */
.text-grey {
  color: rgb(94, 110, 130);
}

.btn-link.focus,
.btn-link:focus {
  text-decoration: underline;
  box-shadow: 0 0 0 0rem rgb(0 123 255 / 25%) !important;
}

.select-search__input {
  color: #495057;
  font-size: 1rem;
}

.btn-small {
  font-size: 80% !important;
}

.chrome-picker {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(120px, 0px);
}

.color-preview {
  cursor: pointer;
}

.reload-color {
  color: rgb(94, 110, 130) !important;
  float: right;
}

.music-card {
  margin-top: 20px;

  padding: 12px;
}

.music-card .label {
  font-weight: 300;
  font-weight: 300;
  line-height: 1.4 !important;
}

.music-card .label.time {
  font-size: 70%;
  font-weight: 400;
  line-height: 1rem;
  float: right;
  text-align: right;
}

.music-card .value {
  font-weight: 600;
}

.tag {
  color: rgb(94, 110, 130);
  font-size: 0.8rem;
  border: 1px solid rgb(94, 110, 130);
  border-radius: 8px;
  padding: 2px 5px;
  margin: 5px;
  display: inline-block;
}

body,
#root,
.navbar-container {
  min-height: 90vh;
}
.navbar-container .nav-menu-items h3 {
  position: absolute;
  top: 0px;
  transform: translate(30px, -15px);
  color: var(--grey);
  font-weight: 800;
}

.icon-row {
  top: 0;
  position: absolute;
  right: 0;
  margin: 26px 30px;
}

.icon-row svg {
  margin-left: 10px;
}

.dropdown {
  display: inline-block;
}

.icon-row svg,
.icon-row div {
  cursor: pointer;
  color: var(--grey);
}

.icon-row svg:hover {
  color: #007bff;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
}

.loading.full {
  height: 100vh;
}

.center-single {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 80px;
  position: relative;
}

.center-single .music-card {
  min-width: 90vw;
}

.infinite-scroll-component {
  overflow-x: hidden;
}

.delete-playlist {
  color: var(--grey);
  cursor: pointer;
}

.delete-playlist:hover {
  color: #fe6549;
}

.share-playlist {
  color: var(--grey);
  cursor: pointer;
}

.share-playlist:hover {
  color: #2fcc8b;
}

.dropdown-dark {
  box-shadow: 0px 2px 7px #0000001a;
  padding: 0px;
}
.dropdown-dark .select-search__input {
  margin: 0px;
}

.playlist .music-card {
  position: relative;
}

.card-info-grid {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr;
  gap: 15px;
  place-items: center stretch;
}

.card-right-info {
  text-align: right !important;
  margin-right: 5px;
}

.spinning {
  animation: rotating 1s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.playlist .music-card .icon-row {
  margin-top: 5px !important;
}